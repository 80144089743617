<template>
  <div class="nav-container">
    <div class="center-region">
      <img class="logo" style="zoom:0.25" src="../../public/img/logo1.png"/>
      <div class="centered-text">心狗健康远程医疗</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "remote_nav"
}
</script>

<style scoped>
.nav-container {
  width: 100%;
  height: 30px;
  padding: 10px 10px;
  background-color: #a21309;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-region {
  display: flex;
  align-items: center;
}

.centered-text {
  font-size: 25px;
  margin-left: 10px;
}
</style>