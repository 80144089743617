<template>
  <el-input :placeholder="label" :type="type" v-model="infoValue">
    <template slot="prepend">
      <span class="label">{{ label }}</span>
    </template>
  </el-input>
</template>

<script>
import { getUrlParam } from '@/utils/utils.js';
import Cookies from "js-cookie";
export default {
  name: 'remote_info_input',
  props: {
    label: String,
  },
  data() {
    return {
      type: 'string',
      infoValue: '',
    };
  },
  watch: {
    infoValue: {
      immediate: true,
      handler(val) {
        this.$emit('change', this.type === 'number' ? Number(val) : val);
      },
    },
  },
  mounted() {
    switch (this.label) {
      case 'userId': {
        /* 用户ID，与医生用户绑定，在医生端的预约界面获取，通过cookie传递*/
        const userId = getUrlParam('userId');
        // this.infoValue = userId ? userId : this.$route.query.UID;
        // this.infoValue = userId ? userId : Cookies.get('PID')? userId : this.$route.query.UserId;
        this.infoValue = userId ? userId : this.$route.query.UserId;
        console.log("看看UID")
        console.log(this.infoValue)
        break;
      }
      case 'roomId': {
        /* 室ID，与医生用户绑定，在医生端的预约界面获取，通过cookie传递*/
        const roomId = getUrlParam('roomId');
        this.type = 'number';
        // this.infoValue = roomId ? roomId : this.$route.query.RID;
        // this.infoValue = roomId ? roomId : Cookies.get('RID')? roomId : this.$route.query.UserId;
        this.infoValue = roomId ? roomId : this.$route.query.UserId;
        console.log("看看RID")
        console.log(this.infoValue)
        break;
      }
      case 'sdkAppId': {
        /* 与腾讯云账号关联，后期要替换，暂时写在前端，实际需改为从后端获取*/
        const sdkAppId = getUrlParam('sdkAppId');
        this.type = 'number';
        this.infoValue = sdkAppId ? sdkAppId : '1400679197';
        console.log("看看sdkAppId")
        console.log(this.infoValue)
        break;
      }
      case 'secretKey': {
        /* 与腾讯云账号关联，后期要替换，暂时写在前端，实际需改为从后端获取*/
        const secretKey = getUrlParam('secretKey');
        this.infoValue = secretKey ? secretKey : 'eb0260efee2dd0afe1351537ecec24caedc71fc0ff1a83b66dbb62d5db15a32c';
        console.log("看看secretKey")
        console.log(this.infoValue)
        break;
      }
      default:
        break;
    }
  },
};
</script>

<style scoped>
.label {
  width: 80px;
  display: inline-block;
  font-weight: bold;
}
</style>